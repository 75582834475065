import { getItem, postItem, deleteItem } from '@/api/api-methods';
// import { api } from '@/api/axios-base';
import { mapState, mapGetters } from 'vuex';
// import { utilsMixin } from '../../../shared/mixins/utilsMixin';
import { generalRepoMixin } from '../../../shared/mixins/generalRepoMixin';
import { validationFormMixin } from '../../../shared/mixins/validationFormMixin';
import KnAddress from '../../../shared/components/KnAddress.vue';
import KnBackToTopButton from '../../../shared/components/KnBackToTopButton.vue';
import KnContactInfo from '../../../shared/components/KnContactInfo.vue';
import KnCvDoc from '../../../shared/components/KnCvDoc.vue';
import KnDownloadDocItem from '../../../shared/components/KnDownloadDocItem.vue';
import KnFormNoteOfMandatory from '../../../shared/components/KnFormNoteOfMandatory.vue';
import KnFormTitle from '../../../shared/components/KnFormTitle.vue';
import KnImageDocItem from '../../../shared/components/KnImageDocItem.vue';
import KnInstitutionalInfo from '../../../shared/components/KnInstitutionalInfo.vue';
import KnLocalAlert from '../../../shared/components/KnLocalAlert.vue';
import KnPersonalInfo from '../../../shared/components/KnPersonalInfo.vue';
import KnTabs from '../../../shared/components/KnTabs.vue';
import KnTaxInfo from '../../../shared/components/KnTaxInfo/KnTaxInfo.vue';
import KnFormSubtitle from '../../../shared/components/KnFormSubtitle/KnFormSubtitle.vue';
import KnFormActionButtons from '../../../shared/components/KnFormActionButtons/KnFormActionButtons.vue';
import KnDocItem from '../../../shared/components/KnDocItem.vue';
import KnSelect from '../../../shared/components/KnSelect.vue';
import KnCheckBox from '../../../shared/components/KnCheckBox.vue';
import {
  postAssignTeacherToGroup,
  postRemoveTeacherFromGroup,
  postUpdateEmailUser,
  postUpdateUserTypeAndPermissions,
  putEmployee,
  registerEmployee,
} from '../../helpers/employeeOptions';
import {
  createFormDataFromObject,
  generatePassword,
  getFullName,
} from '../../../shared/helpers/dataUtils';
// import { checkEmailAvailability } from '../../../shared/helpers/dataOptions';
import {
  canAdd,
  canChange,
  insufficientPermissionsMessage,
} from '../../../shared/helpers/permissionsUtils';
import {
  fetchGroupSucursals,
  fetchSchoolCycles,
} from '../../../configuration/helpers/KnGroupsOptions';
import { ACTIONS_GROUP } from '../../../students/helpers/states';
import { fetchGroups } from '../../helpers/reportCardOptions';
import { postDocument, postImage } from '../../../shared/helpers/dataOptions';

const VALID_DOC_TYPES = [
  'Solicitud de empleo',
  'Carta de recomendación',
  'Identificación oficial',
  'Comprobante de estudios',
  'Comprobante de domicilio',
];

export default {
  components: {
    KnPersonalInfo,
    KnFormSubtitle,
    KnFormActionButtons,
    KnTaxInfo,
    KnAddress,
    KnInstitutionalInfo,
    KnContactInfo,
    KnLocalAlert,
    KnDownloadDocItem,
    KnTabs,
    KnImageDocItem,
    KnDocItem,
    KnFormTitle,
    KnBackToTopButton,
    KnCvDoc,
    KnFormNoteOfMandatory,
    KnCheckBox,
    KnSelect,
  },
  mixins: [generalRepoMixin, validationFormMixin],
  props: {
    entity: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      tabs: [
        { name: 'Datos personales', value: 33 },
        { name: 'Documentación', value: 66 },
        { name: 'Datos fiscales', value: 100 },
      ],
      valueDeterminate: 33,
      routerName: 'Empleados',
      resource: 'empleado',
      employeePersonalInfo: {
        primer_nombre: null,
        segundo_nombre: '',
        apellido_paterno: null,
        apellido_materno: '',
        genero: null,
        fecha_nacimiento: null,
        edad: null,
        numero_identidad_CURP: '',
        titular_grupo: false,
        institucion_educativa: null,
        id_tipo_usuario: null,
      },
      employeeAddress: {
        estado: null,
        ciudad: '',
        municipio: '',
        codigo_postal: null,
        colonia: null,
        calle: null,
        numero_exterior: null,
        numero_interior: '',
        numero_contacto: '',
      },
      employeeTaxInfo: {
        tax_id: null,
        nombre_o_razon_social: null,
        uso_factura: null,
      },
      employeeId: null,
      loading: false,
      fillAddressFrom: false,
      cvDoc: null,
      cvDocType: null,
      docs: [],
      docTypes: [],
      docsSelected: [],
      docsToDelete: [],
      photoProfile: null,
      documentProfile: null,
      employeePhoto: null,
      group: {
        nombre_grupo: null,
        sucursal: 1,
        ciclo_escolar: null,
        alumnos: null,
        profesores: '',
      },
      employeeType: {
        dato: '',
        institucion_educativa: null,
      },
      groups: [],
      password: null,
      userName: null,
      employeeUserId: null,
      // alertText: "",
      // alertType: "info",
      // alertColor: "info",
      // showAlert: false,
      warnings: [],
      idImage: null,
      branches: [],
      branchId: 1,
      useTaxInfoGeneric: true,
      genericTaxInfo: {
        tax_id: 'XAXX010101000',
        nombre_o_razon_social: 'PÚBLICO EN GENERAL',
        regimen_fiscal: '616',
        uso_factura: null,
      },
      genericCFDIUse: null,
      updateUsername: false,
      selectedGroupId: null,
      schoolYears: [],
      selectedSchoolYear: null,
      groupActions: [
        {
          text: 'Reasignar',
          value: ACTIONS_GROUP.REASSIGN,
          hasPermissions: () => canChange('empleado') || canChange('grupo'),
        },
        {
          text: 'Desasignar',
          value: ACTIONS_GROUP.UNASSIGN,
          hasPermissions: () => canChange('empleado') || canChange('grupo'),
        },
      ],
      selectedGroupAction: null,
      loadingGroups: false,
    };
  },
  computed: {
    ...mapState(['institutionId', 'userData']),
    ...mapGetters(['schoolControlEnabled']),
    title() {
      return this.entity === null ? 'Agregar empleado' : 'Editar empleado';
    },
    isNewMode() {
      return this.entity === null;
    },
    successAlertText() {
      return this.isNewMode
        ? `Empleado registrado con exito! \nUsuario: ${this.userName}, contraseña: ${this.password}`
        : 'Empleado actualizado con exito!';
    },
    successAlertType() {
      return this.isNewMode ? 'success' : 'info';
    },
    invalidFields() {
      //console.log('employeePersonalInfo tiene nulls?', this.objectHasNulls(this.employeePersonalInfo), this.employeePersonalInfo)
      //console.log('employeeAddress tiene nulls?', this.objectHasNulls(this.employeeAddress), this.employeeAddress)
      //console.log('employeeTaxInfo tiene nulls?', this.objectHasNulls(this.employeeTaxInfo), this.employeeTaxInfo)
      return (
        this.isNewMode &&
        (this.objectHasNulls(this.employeePersonalInfo) ||
          this.objectHasNulls(this.employeeAddress) ||
          this.objectHasNulls(this.employeeTaxInfo))
      );
    },
    isReadonly() {
      return this.isNewMode ? false : !canChange(this.resource);
    },
    warningText() {
      return this.warnings.length ? this.warnings.join(',') : null;
    },
    checkIfUserTypeChange() {
      return this.isNewMode
        ? false
        : this.entity.usuario.tipo_usuario !==
            this.employeePersonalInfo.id_tipo_usuario;
    },
    checkIfGroupChange() {
      return this.isNewMode
        ? this.employeePersonalInfo.grupo
        : this.entity.groupId !== this.employeePersonalInfo.grupo;
    },
    allowedGroupActions() {
      return this.groupActions.filter((action) => action.hasPermissions());
    },
    hasGroup() {
      return (
        this.employeePersonalInfo.grupo !== null &&
        this.employeePersonalInfo.grupo !== undefined
      );
    },
    assignGroupText() {
      return !this.hasGroup ? 'Asignar grupo' : 'Grupo';
    },
    showGroupSelect() {
      return (
        this.isNewMode ||
        !this.hasGroup ||
        this.selectedGroupAction === ACTIONS_GROUP.REASSIGN
      );
    },
    employeeDocumentationFiles() {
      return this.docs.filter((doc) => VALID_DOC_TYPES.includes(doc.label));
    },
  },
  async created() {
    this.loading = true;
    this.alertText = 'Por favor, espere. Cargando...';
    this.showAlert = true;
    this.alertType = 'info';

    //const allDocTypes = await this.fetchDocumentTypes()
    this.docTypes = await getItem(
      '/app-personas/tipo-documento?estatus_sistema=true&limit=100'
    );

    const s01Use = await getItem(
      '/app-personas/filters/uso-factura?dato=sin efectos&estatus_sistema=true'
    );
    const { ok: okBranches, data: dataBranches } = await fetchGroupSucursals({
      institutionId: this.institutionId,
      systemStatus: true,
    });
    const { ok: okSchoolYear, data: dataSchoolYear } = await fetchSchoolCycles({
      institutionId: this.institutionId,
      systemStatus: true,
      limit: 20,
    });
    if (s01Use.results && s01Use.results.length) {
      this.genericCFDIUse = s01Use.results[0];
      this.genericTaxInfo.uso_factura = this.genericCFDIUse.id;
      this.employeeTaxInfo = { ...this.genericTaxInfo };
    }
    this.schoolYears = okSchoolYear ? dataSchoolYear : [];
    this.branches = okBranches ? dataBranches : [];
    if (this.branches.length) {
      this.branchId = this.branches[0].id;
    }
    this.selectedGroupAction = ACTIONS_GROUP.ASSIGN;
    this.buildDocArray();
    if (!this.isNewMode) {
      this.fillData();
    } else {
      this.employeePersonalInfo.institucion_educativa = this.institutionId;
    }
    this.showAlert = false;
  },
  methods: {
    async getGroups() {
      try {
        this.loadingGroups = true;
        const { ok, data } = await fetchGroups({
          institutionId: this.institutionId,
          schoolCycleId: this.selectedSchoolYear,
          systemStatus: true,
          limit: 100,
        });
        ok ? (this.groups = data) : (this.groups = []);
      } catch (error) {
        console.error('Error al intentar obtener grupos');
      } finally {
        this.loadingGroups = false;
      }
    },

    async updateUserTypeAndPermissions(
      userData = { id_usuario: null, id_nuevo_tipo_usuario: null }
    ) {
      try {
        // console.log('updateUserTypeAndPermissions ', userData);

        const { ok, message } = await postUpdateUserTypeAndPermissions(
          userData
        );
        if (ok) {
          this.alertText = 'Permisos de usuario actualizados exitosamente';
        } else {
          this.warnings.push(message);
        }
      } catch (error) {
        this.warnings.push(
          'Error al intentar actualizar permisos del usuario. ' + error
        );
      }
    },
    async updateEmailUser(userData = { id_usuario: null, email: null }) {
      try {
        const { ok, message } = await postUpdateEmailUser(userData);
        if (ok) {
          this.warnings.push('Usuario y contraseña actualizados exitosamente.');
        } else {
          this.warnings.push(
            'No se pudo actualizar el usuario y la contraseña. ' + message
          );
        }
      } catch (error) {
        this.warnings.push(
          'Error al intentar actualizar usuario y la contraseña. ' + error
        );
      }
    },
    async assignTeacherToGroup(employeeId, groupId) {
      try {
        if (!employeeId || !groupId) return;
        const { ok, message } = await postAssignTeacherToGroup(
          employeeId,
          groupId
        );
        if (!ok) {
          this.warnings.push(message);
        }
      } catch (error) {
        this.warnings.push(error.toString);
      }
    },
    async unassignTeacherFromGroup(employeeId, groupId) {
      try {
        if (!employeeId || !groupId) return;
        const { ok, message } = await postRemoveTeacherFromGroup(
          employeeId,
          groupId
        );
        if (!ok) {
          this.warnings.push(message);
        }
      } catch (error) {
        this.warnings.push(error.toString);
      }
    },
    async reassignTeacherFromGroup(employeeId, oldGroupId, newGroupId) {
      try {
        if (!employeeId || !oldGroupId || !newGroupId) return;
        await this.unassignTeacherFromGroup(employeeId, oldGroupId);
        await this.assignTeacherToGroup(employeeId, newGroupId);
      } catch (error) {
        this.warnings.push(error.toString);
      }
    },
    async handleTeacherGroupActions() {
      const groupId = this.employeePersonalInfo.grupo;
      console.log(
        'Dentro de handleTeacherGroupActions',
        groupId,
        this.selectedGroupAction
      );

      switch (this.selectedGroupAction) {
        case ACTIONS_GROUP.ASSIGN:
          this.assignTeacherToGroup(this.employeeId, groupId);
          break;
        case ACTIONS_GROUP.REASSIGN:
          await this.reassignTeacherFromGroup(
            this.employeeId,
            this.entity.groupId,
            groupId
          );
          break;
        case ACTIONS_GROUP.UNASSIGN:
          await this.unassignTeacherFromGroup(
            this.employeeId,
            this.entity.groupId
          );
          break;
      }
    },
    async createEmployee() {
      try {
        this.alertText = 'Creando empleado...';
        this.employeeAddress.numero_contacto =
          this.employeePersonalInfo.telefono_movil;
        this.employeeAddress.institucion_educativa =
          this.employeePersonalInfo.institucion_educativa;
        this.alertText = 'Creando dirección de empleado';
        if (this.employeeAddress && !this.employeeAddress.id) {
          // console.log('Se debe crear direccion');
          await this.postAddress(this.employeeAddress);
        }
        // console.log('Direccion empleado: ', this.employeeAddress);
        // this.employeePersonalInfo.institucion_educativa = this.institutionId;
        this.employeePersonalInfo.direccion = this.employeeAddress.id;
        this.employeePersonalInfo.telefono_casa =
          this.employeePersonalInfo.telefono_movil;
        this.alertText = 'Creando datos personales de empleado';
        this.alertText = 'Creando datos fiscales de empleado';
        this.alertText = 'Creando usuario de empleado';

        let result = null;
        if (this.photoProfile !== undefined && this.photoProfile !== null) {
          const image = {
            nombre_imagen:
              'Foto_perfil_' + getFullName(this.employeePersonalInfo),
            imagen: this.photoProfile.file,
            estatus_sistema: true,
          };
          const imageFormData = createFormDataFromObject(image);
          const { ok, data, message } = await postImage(imageFormData); // this.postImage(formData);
          if (ok) {
            result = data.id;
          } else {
            this.warnings.push(message);
          }
        }
        let resultDocument = [];
        if (this.cvDoc) {
          this.docsSelected.push(this.cvDoc);
        }
        for (const iterator of this.docsSelected) {
          // console.log('Documento: ', iterator);
          if (iterator.file !== undefined || iterator.file !== null) {
            const doc = {
              nombre_documento: iterator.fileName,
              documento: iterator.file,
              tipo_documento: iterator.type,
              autor: this.userData.id,
              estatus_sistema: true,
            };
            const docFormData = createFormDataFromObject(doc);
            const { ok, data, message } = await postDocument(docFormData);
            if (ok) {
              resultDocument.push(data.id);
            } else {
              this.warnings.push(message);
            }
          }
        }

        const uso_factura = await getItem(
          `/app-personas/uso-factura/${this.employeeTaxInfo.uso_factura}`
        );

        const employeeData = {
          /** Datos personales */
          primer_nombre: this.employeePersonalInfo.primer_nombre,
          segundo_nombre: this.employeePersonalInfo.segundo_nombre,
          apellido_paterno: this.employeePersonalInfo.apellido_paterno,
          apellido_materno: this.employeePersonalInfo.apellido_materno,
          numero_identidad_CURP:
            this.employeePersonalInfo.numero_identidad_CURP,
          fecha_nacimiento: this.employeePersonalInfo.fecha_nacimiento,
          edad: this.employeePersonalInfo.edad,
          id_direccion: this.employeePersonalInfo.direccion,
          id_genero: this.employeePersonalInfo.genero,
          telefono_casa: this.employeePersonalInfo.telefono_movil,
          telefono_movil: this.employeePersonalInfo.telefono_movil,

          /** Datos fiscales */
          tax_id: this.employeeTaxInfo.tax_id,
          nombre_o_razon_social: this.employeeTaxInfo.nombre_o_razon_social,
          uso_factura: uso_factura.clave,
          regimen_fiscal: this.employeeTaxInfo.regimen_fiscal,
          moneda: this.employeeTaxInfo.moneda,
          lugar_expedicion: this.employeeTaxInfo.lugar_expedicion,
          tipo_cfdi: this.employeeTaxInfo.tipo_cfdi,
          condiciones_pago: this.employeeTaxInfo.condiciones_pago,
          forma_pago: this.employeeTaxInfo.forma_pago,
          metodo_pago: this.employeeTaxInfo.metodo_pago,

          /** Usuario */
          email: this.employeePersonalInfo.email,
          password: generatePassword(this.employeePersonalInfo),
          id_tipo_usuario: this.employeePersonalInfo.id_tipo_usuario,

          /** Empleado */
          id_sucursal: this.branchId,
          id_foto_perfil: result ? result : null,
          id_tipo_empleado: this.employeePersonalInfo.tipo_empleado,
          ids_documentos_empleado: resultDocument ? resultDocument : [],
          id_institucion_educativa:
            this.employeePersonalInfo.institucion_educativa,
        };
        // console.log('EmployeeData', employeeData);
        const { ok, data, message } = await registerEmployee(employeeData);
        if (!ok) {
          this.errors.push(message);
        } else {
          // console.log('Datos creados exitosamente', data);
          this.userName = employeeData.email;
          this.password = employeeData.password;
          this.employeeId = data.id;
        }
        // await this.postEmployee(employeeObj);
        // this.alertText = `Usuario: ${userObj.nombre_usuario}, Contraseña: ${userObj.password}`;
      } catch (error) {
        this.errors.push('Error al intentar crear empleado. ' + error);
        console.error('Error al crear empleo.', error);
      }
    },
    //Metodo que ejecuta la actualizacion de empleados
    async updateEmployee() {
      try {
        this.alertText = 'Actualizando empleado...';
        this.employeeAddress.numero_contacto =
          this.employeePersonalInfo.telefono_movil;
        this.employeeAddress.institucion_educativa =
          this.employeePersonalInfo.institucion_educativa;
        this.alertText = 'Actualizando dirección de empleado';
        const employeeAddressObj = {
          calle: this.employeeAddress.calle,
          numero_exterior: this.employeeAddress.numero_exterior,
          numero_interior: this.employeeAddress.numero_interior,
          colonia: this.employeeAddress.colonia,
          ciudad: this.employeeAddress.ciudad,
          estado: this.employeeAddress.estado,
          numero_contacto: this.employeeAddress.numero_contacto,
          codigo_postal: this.employeeAddress.codigo_postal,
          institucion_educativa:
            this.employeePersonalInfo.institucion_educativa,
        };
        await this.updateAddress(
          employeeAddressObj,
          this.employeePersonalInfo.direccion
        );
        // this.employeePersonalInfo.institucion_educativa =
        //   this.employeePersonalInfo.institucion_educativa;
        this.employeePersonalInfo.direccion = this.employeeAddress.id;
        this.employeePersonalInfo.telefono_casa =
          this.employeePersonalInfo.telefono_movil;
        // this.employeePersonalInfo.numero_identidad_CURP = '';
        this.alertText = 'Actualizando datos personales de empleado';
        await this.updatePersonalInfo(
          this.employeePersonalInfo,
          this.employeePersonalInfo.id
        );
        this.alertText = 'Actualizando datos fiscales de empleado';
        await this.updateTaxInfo(this.employeeTaxInfo, this.employeeTaxInfo.id);

        let result = null;
        if (this.photoProfile !== undefined && this.photoProfile !== null) {
          const image = {
            nombre_imagen:
              'Foto_perfil_' + getFullName(this.employeePersonalInfo),
            imagen: this.photoProfile.file,
            estatus_sistema: true,
          };
          const imageFormData = createFormDataFromObject(image);
          const { ok, data, message } = await postImage(imageFormData); // this.postImage(formData);
          if (ok) {
            result = data.id;
          } else {
            this.warnings.push(message);
          }
        }

        let resultDocument = [];
        for (const iterator of this.docsSelected) {
          // console.log('Documento: ', iterator);
          if (iterator.file !== undefined || iterator.file !== null) {
            const doc = {
              nombre_documento: iterator.fileName,
              documento: iterator.file,
              tipo_documento: iterator.type,
              autor: this.userData.id,
              estatus_sistema: true,
            };
            const docFormData = createFormDataFromObject(doc);
            const { ok, data, message } = await postDocument(docFormData);
            if (ok) {
              resultDocument.push(data.id);
            } else {
              this.warnings.push(message);
            }
          }
        }

        const employeeObj = {
          id: this.employeeId,
          usuario: this.entity.usuario.id,
          datos_personales: this.employeePersonalInfo.id,
          datos_fiscales: this.employeeTaxInfo.id,
          foto_perfil:
            this.photoProfile === undefined || this.photoProfile === null
              ? this.idImage
              : result,
          documentos_empleado:
            this.docsSelected.length === undefined ||
            this.docsSelected.length === null ||
            this.docsSelected.length === 0
              ? this.entity.documentos_empleado.id
              : resultDocument,
          institucion_educativa:
            this.employeePersonalInfo.institucion_educativa,
          tipo_empleado: this.employeePersonalInfo.tipo_empleado,
          titular_grupo: this.employeePersonalInfo.titular_grupo,
          sucursal: this.branchId,
        };
        this.alertText = 'Actualizando empleado';
        await putEmployee(employeeObj);
        // console.log('Profesor grupo', this.employeePersonalInfo.grupo);
        if (this.checkIfUserTypeChange) {
          await this.updateUserTypeAndPermissions({
            id_usuario: this.employeeUserId,
            id_nuevo_tipo_usuario: this.employeePersonalInfo.id_tipo_usuario,
          });
        }
        if (this.updateUsername) {
          await this.updateEmailUser({
            id_usuario: this.employeeUserId,
            email: this.employeePersonalInfo.email,
          });
        }
      } catch (error) {
        this.errors.push('Error al intentar actualizar empleado');
        console.error('Error al intentar actualizar empleado.', error);
      }
    },

    async createObjects() {
      try {
        this.loading = true;
        this.alertText = 'Cargando...';
        this.showAlert = true;
        await this.createEmployee();
        // console.log('Hay errores despues de crear empleado. ', this.errors);
        if (!this.errors.length) {
          const groupId = this.employeePersonalInfo.grupo;
          if (this.selectedGroupAction === ACTIONS_GROUP.ASSIGN && groupId) {
            await this.assignTeacherToGroup(this.employeeId, groupId);
          }
        }
        if (this.errors.length) {
          this.alertType = 'error';
          this.alertText = this.errors.join(', ');
        } else {
          this.alertType = this.successAlertType;
          this.alertColor = 'success';
          this.alertText = this.successAlertText;
        }
        this.loading = false;
        this.password = null;
        this.userName = null;
      } catch (error) {
        console.error(
          'Error al dar de alta Empleado y objetos relacionados',
          error
        );
      }
    },
    async updateObjects() {
      try {
        this.loading = true;
        this.alertText = 'Cargando...';
        this.showAlert = true;
        await this.updateEmployee();

        console.log('Despues de actualizar empleado');

        if (this.errors.length === 0) {
          console.log('No hubo errores');

          // Primero eliminamos los documentos que se
          // se van a actualizar
          this.alertText = 'Limpiando documentos';
          if (this.docsToDelete.length > 0) {
            console.log('Dentro de docsToDelete');

            for (const docId of this.docsToDelete) {
              await this.removeDocumentFromEmployee(docId, this.employeeId);
              await this.deleteDocumentPermanently(docId);
            }
          }

          console.log('A realizar acciones de grupo');

          await this.handleTeacherGroupActions();
        }
        this.loading = false;
        if (this.errors.length > 0) {
          this.alertType = 'error';
          this.alertText = this.errors.join(', ');
        } else {
          this.alertType = this.successAlertType;
          this.alertColor = 'success';
          this.alertText = this.successAlertText;
        }
      } catch (error) {
        this.loading = false;
        // console.log(
        //   'Error al actualizar Empleado y objetos relacionados',
        //   error
        // );
        this.errors.push(
          'Error al actualizar Empleado y objetos relacionados. ' + error
        );
        this.alertType = 'error';
        this.alertText = this.errors.join(', ');
      }
    },
    setTabValue(val) {
      this.valueDeterminate = val;
    },
    async save() {
      if (this.isNewMode) {
        if (canAdd(this.resource)) {
          this.createObjects();
        } else {
          this.insufficientPermissionAlert();
        }
      } else {
        if (canChange(this.resource)) {
          this.updateObjects();
        } else {
          this.insufficientPermissionAlert();
        }
      }
    },
    insufficientPermissionAlert() {
      this.alertType = 'info';
      this.alertText = insufficientPermissionsMessage();
      this.alertColor = 'warning';
      this.loading = false;
      this.showAlert = true;
    },
    cancel() {
      this.returnToTable();
    },
    actionAlertBtn1() {
      if (this.alertType === 'success' || this.alertType === 'info') {
        this.returnToTable();
      } else {
        this.closeAlert();
      }
    },
    continueAdding() {
      this.clean();
      this.closeAlert();
    },
    returnToTable() {
      this.$router.replace({ name: this.routerName });
    },
    closeAlert() {
      this.errors = [];
      this.warnings = [];
      this.showAlert = false;
    },
    clean() {
      window.scroll({
        top: 50,
        left: 0,
        behavior: 'smooth',
      });
      this.valueDeterminate = 33;
      this.employeePersonalInfo = {
        primer_nombre: null,
        segundo_nombre: '',
        apellido_paterno: null,
        apellido_materno: '',
        genero: null,
        fecha_nacimiento: null,
        edad: null,
        numero_identidad_CURP: '',
        titular_grupo: false,
        institucion_educativa: this.institutionId,
        id_tipo_usuario: null,
      };
      this.employeeAddress = {
        estado: null,
        ciudad: '',
        municipio: '',
        codigo_postal: null,
        colonia: null,
        calle: null,
        numero_exterior: null,
        numero_interior: '',
        numero_contacto: '',
        institucion_educativa: this.institutionId,
      };
      this.employeeTaxInfo = { ...this.genericTaxInfo };
      this.employeeId = null;
      this.docs = [];
      this.buildDocArray();

      this.docsSelected = [];
      this.docsToDelete = [];
    },
    async fillData() {
      /*let responseGroups = await getItem(
        `/app-personas/filters/grupo?institucion_educativa=${this.institutionId}`
      );
      const filterGroup = responseGroups.results.filter((grp) => {
        return grp.profesores.find((prof) => prof.id === this.employeeId);
      });
      let group = 'Sin asignar';
      let groupId = null;
      if (filterGroup && filterGroup.length > 0) {
        group = filterGroup[0].nombre_grupo;
        groupId = filterGroup[0].id;
      }
      console.log('Nombre grupo', group)*/
      this.employeeId = this.entity.id;
      this.employeePhoto = this.entity.foto_perfil;
      if (
        this.entity.foto_perfil !== undefined &&
        this.entity.foto_perfil !== null
      ) {
        this.employeePhoto = this.entity.foto_perfil;
        this.idImage = this.entity.foto_perfil.id;
      }

      this.employeeUserId = this.entity.usuario.id;
      // console.log('Personal grupo', this.employeePersonalInfo.grupo);
      // console.log('Entity grupo', this.entity.groupId);

      this.entity.groupId
        ? (this.selectedGroupAction = null)
        : (this.selectedGroupAction = ACTIONS_GROUP.ASSIGN);

      this.employeePersonalInfo.url_foto = this.employeePhoto;
      this.employeePersonalInfo = { ...this.entity.datos_personales };
      // this.employeePersonalInfo.numero_identidad_CURP
      this.employeePersonalInfo.tipo_empleado = this.entity.tipo_empleado.id;
      this.employeePersonalInfo.id_tipo_usuario =
        this.entity.usuario.tipo_usuario;
      this.employeePersonalInfo.grupo = this.entity.groupId;
      this.employeePersonalInfo.titular_grupo = this.entity.titular_grupo;
      this.employeePersonalInfo.institucion_educativa =
        this.entity.institucion_educativa.id;
      this.fillAddressFrom = false;
      const employeeAddress = await getItem(
        `/app-personas/direccion/${this.employeePersonalInfo.direccion}`
      );
      this.employeeAddress = { ...employeeAddress };
      this.employeeAddress.codigo_postal = employeeAddress.codigo_postal.id;
      this.employeeAddress.code = employeeAddress.codigo_postal.codigo_postal;
      this.employeeAddress.municipio =
        employeeAddress.codigo_postal.nombre_municipio;
      this.fillAddressFrom = true;
      this.employeePersonalInfo.institucion_educativa =
        this.entity.institucion_educativa.id;
      this.employeeTaxInfo = { ...this.entity.datos_fiscales };
      if (this.entity.documentos_empleado.length) {
        const employeeDocs = [...this.entity.documentos_empleado];
        //console.log("Documentos empleado", employeeDocs);
        const cvType = this.findDocTypeByName('Curriculum Vitae');
        const cvDoc = employeeDocs.find((doc) => doc.tipo_documento === cvType);
        //console.log("Documentos", cvDoc);
        if (cvDoc) {
          this.cvDoc = cvDoc;
        }
        const copyDocs = this.docs;
        copyDocs.forEach((doc, index) => {
          const docFinded = employeeDocs.find(
            (employeeDoc) => employeeDoc.tipo_documento === doc.type
          );
          if (docFinded) {
            const newDoc = { ...doc, ...docFinded };
            this.docs[index] = newDoc;
          }
        });
        // console.log('Docs llenado:', this.docs);
      }
    },
    /** Metodos para foto **/
    savePhoto(file) {
      // console.log('foto file:', file);
      this.photoProfile = file;
    },
    clearPhoto() {
      this.photoProfile = null;
    },
    clearImage() {
      this.employeePhoto = null;
      this.employeePersonalInfo.url_foto = '';
    },
    async buildDocArray() {
      try {
        this.docTypes.results.forEach((docType) => {
          if (docType.dato.includes('Curriculum Vitae')) {
            this.cvDocType = docType.id;
          } else {
            // console.log('Todos los datos', this.docTypes);
            const newDoc = {
              type: docType.id,
              label: docType.dato,
              file: null,
            };
            this.docs.push(newDoc);
          }
        });
      } catch (error) {
        console.error('Error al intentar construir array de documentos');
      }
    },
    findDocTypeByName(name) {
      return this.docTypes.results.find((docType) =>
        docType.dato.includes(name)
      );
    },
    findDocInDocsSelected(doc) {
      // console.log('doc findDocInDocsSelected', doc);
      const docFinded = this.docsSelected.find(
        (docSelected) => docSelected.type === doc.type
      );
      // console.log('docFinded', docFinded);
      return docFinded ? docFinded : null;
    },
    saveCV(file) {
      // console.log('file CV:', file);
      this.cvDoc = file;
    },
    clearCV(file) {
      this.cvDoc = null;
      if (!this.isNewMode && file.id !== null) {
        this.docsToDelete.push(file.id);
      }
    },
    saveFiles(file) {
      // console.log('El archivo seleccionado:', file);
      const fileIndex = this.docsSelected.findIndex(
        (doc) => doc.type === file.type
      );
      if (fileIndex !== -1) {
        this.docsSelected[fileIndex] = file;
      } else {
        this.docsSelected.push(file);
      }
      // console.log('Array de archivos:', this.docsSelected);
    },
    clearFiles(file) {
      // console.log('Archivo a eliminar:', file);
      this.docsSelected = this.docsSelected.filter(
        (doc) => doc.file !== file.file
      );
      // console.log('Array de archivos:', this.docsSelected);
    },
    //TODO: Revisar funcionamiento de los metodos
    // para el manejo de documentos del empleado
    async deleteFile(file) {
      // console.log('Archivo a eliminar:', file);
      const copyDocs = [...this.docs];
      const resetFile = {
        id: null,
        type: file.type,
        label: file.label,
        file: null,
      };
      this.docsToDelete.push(file.id);
      const fileIndex = copyDocs.findIndex((doc) => doc.id === file.id);
      if (fileIndex !== -1) {
        copyDocs[fileIndex] = resetFile;
        this.docs = copyDocs;
      }
      // console.log('Docs despues de eliminar:', copyDocs);
    },

    async removeDocumentFromEmployee(documentId, employeeId) {
      try {
        const responseData = await postItem(
          '/app-personas/helpers/remove-documento-empleado',
          {
            id_empleado: employeeId,
            id_documento: documentId,
          }
        );
        // console.log('Respuesta remover documento del empleado:', responseData);
        if (responseData.e) {
          this.errors.push(
            'Error al remover documento del empledo: ' +
              responseData.e.join(', ')
          );
        }
      } catch (error) {
        this.errors.push('Error al intentar remover documento al empleado');
        console.error(
          'Error al intentar remover documento al empleado.',
          error
        );
      }
    },
    async deleteDocumentPermanently(documentId) {
      try {
        const responseData = await deleteItem(
          `/app-personas/documento/${documentId}`
        );
        // console.log(
        //   'Respuesta eliminar documento permanentemente:',
        //   responseData
        // );
        if (responseData.e) {
          this.errors.push(
            'Error al eliminar documento permanentemente: ' +
              responseData.e.join(', ')
          );
        }
      } catch (error) {
        this.errors.push(
          'Error al intentar eliminar documento permanentemente'
        );
        console.error(
          'Error al intentar eliminar documento permanentemente.',
          error
        );
      }
    },
  },
};
